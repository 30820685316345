import React, { useRef, useEffect } from "react"
import { Link, injectIntl } from "gatsby-plugin-intl"
import arrowDown from "../images/icons/icon_arrow_down_white.svg"

const HeroGuide = ({ intl, getPaginationData, country }) => {
  const pagRef = useRef(null);

  useEffect(() => {
    if (pagRef.current) getPaginationData('Argentina', pagRef);
  }, [pagRef]);

  return (
      <div
          className="block hero market-guide"
          data-section="hero"
          ref={pagRef}
          style={{ backgroundImage: `url(${require(`../images/market-guides/${country}/mg-hero.jpg`)})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col col-xl-8">
              <div>
                <h1>{intl.formatMessage({ id: `${country}_header_heading` })}</h1>
                <p className="sub">
                  {intl.formatMessage({ id: `${country}_header_subheading` })}
                </p>
              </div>
              <div className="hero-cta">
                <p>{intl.formatMessage({ id: `${country}_header_copy` })}</p>
                <Link
                  className="button primary"
                  to='/download'
                  title={intl.formatMessage({ id: "cta_download" })}
                >
                  {intl.formatMessage({ id: "cta_download" })}
              </Link>
              </div>
            </div>
          </div>
        </div>
        <img className="arrow" src={arrowDown} alt="" />
      </div>
  )
}

export default injectIntl(HeroGuide)
