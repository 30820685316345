import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import { Line } from 'react-chartjs-2';
import { useInView } from 'react-hook-inview';
import { lineOptions, customAxisLines } from './lineChartOptions';
import { camelCase } from 'change-case';
import { Chart } from 'chart.js';

const LineChart = ({ intl, getPaginationData, country }) => {
    const translate = (id) => intl.formatMessage({ id });

    const createGradients = (canvas) => {
        const ctx = canvas.getContext('2d');
        const gradients = {};
        // this px value for creating the gradient will have to be replaced with the width/height of the chart
        gradients.posGradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradients.posGradient.addColorStop(0, 'rgba(1, 91, 126, 0.3)');
        gradients.posGradient.addColorStop(0.7, 'rgba(255, 255, 255, 0.3)');

        gradients.posLineGradient = ctx.createLinearGradient(0, 0, 300, 0);
        gradients.posLineGradient.addColorStop(0, 'rgba(1, 91, 126, 1)');
        gradients.posLineGradient.addColorStop(1, 'rgba(0, 151, 117, 1)');

        gradients.ecomGradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradients.ecomGradient.addColorStop(0, 'rgba(70, 13, 158, 0.3)');
        gradients.ecomGradient.addColorStop(0.7, 'rgba(255, 255, 255, 0.3)');

        gradients.ecomLineGradient = ctx.createLinearGradient(0, 0, 300, 0);
        gradients.ecomLineGradient.addColorStop(0, 'rgba(70, 13, 158, 1)');
        gradients.ecomLineGradient.addColorStop(1, 'rgba(49, 89, 193, 1)');

        return gradients;
    }

    const data = (canvas) => {
        const gradients = createGradients(canvas);

        const countryData = {
            pos: {
                argentina: [90, 95, 104, 107, 108],
                southAfrica: [180, 184, 197, 202, 206],
                malaysia: [140, 149, 165, 172, 179],
                nigeria: [268, 276, 287, 297, 311],
                thailand: [249, 267, 291, 301, 307],
                southKorea: [1033, 1093, 1153, 1173, 1210],
                uae: [256, 268, 288, 304, 309],
            },
            ecom: {
                argentina: [10, 13, 16, 19, 21],
                southAfrica: [5, 6, 7, 8, 9],
                malaysia: [7, 8, 10, 11, 12],
                nigeria: [19, 27, 33, 39, 45],
                thailand: [37, 42, 47, 51, 55],
                southKorea: [90, 105, 118, 131, 142],
                uae: [20, 25, 31, 36, 40],
            },
            desktop: {
                argentina: [5, 6, 7, 8, 8],
                southAfrica: [2, 2, 2, 3, 3],
                malaysia: [3, 3, 4, 4, 4],
                nigeria: [7, 9, 10, 12, 14],
                thailand: [15, 16, 17, 18, 19],
                southKorea: [32, 36, 37, 39, 42],
                uae: [8, 10, 12, 13, 14],
            },
            mobile: {
                argentina: [5, 7, 9, 11, 13],
                southAfrica: [3, 4, 5, 5, 6],
                malaysia: [4, 5, 6, 7, 8],
                nigeria: [12, 18, 23, 27, 31],
                thailand: [22, 26, 30, 33, 36],
                southKorea: [58, 79, 81, 91, 100],
                uae: [11, 15, 19, 23, 26],
            },
        };

        return {
            labels: [
                translate('argentina_ecom_data_turnover_2020E'),
                translate('argentina_ecom_data_turnover_2021F'),
                translate('argentina_ecom_data_turnover_2022F'),
                translate('argentina_ecom_data_turnover_2023F'),
                translate('argentina_ecom_data_turnover_2024F'),
            ],
            datasets: [
                {
                    label: 'POS Turnover',
                    fill:'start',
                    backgroundColor: gradients.posGradient,
                    borderColor: gradients.posLineGradient,
                    pointBackgroundColor: '#007879',
                    pointBorderColor: 'transparent',
                    data: countryData.pos[camelCase(country)],
                },
                {
                    label: 'ECOM Turnover',
                    backgroundColor: gradients.ecomGradient,
                    borderColor: gradients.ecomLineGradient,
                    pointBackgroundColor: '#3647B9',
                    pointBorderColor: 'transparent',
                    data: countryData.ecom[camelCase(country)],
                },
                {
                    label: 'Mobile',
                    borderColor: '#A18CDE',
                    pointBackgroundColor: '#A18CDE',
                    pointBorderColor: 'transparent',
                    data: countryData.mobile[camelCase(country)],
                },
                {
                    label: 'Desktop',
                    borderColor: 'rgba(27, 27, 111, 1)',
                    pointBackgroundColor: 'rgba(27, 27, 111, 1)',
                    pointBorderColor: 'transparent',
                    data: countryData.desktop[camelCase(country)],
                },
            ]
        }
    };

    const pagRef = useRef(null);

    const [ref, isVisible] = useInView({
      threshold: 0.4,
    })

    useEffect(() => {
      if (pagRef.current) getPaginationData('Projected Growth', pagRef);
    }, [pagRef]);

    const [animationRan, setAnimationRan] = useState(false);

    useEffect(() => {
      if (isVisible && !animationRan) {
          setAnimationRan(true);
          setTimeout(() => lineOptions(country).animation.duration = 0, 0);
      }
    }, [isVisible]);

    useEffect(() => {
      const customAxis = customAxisLines(country);
      Chart.pluginService.register(customAxis);

      return () => {
        Chart.pluginService.unregister(customAxis);

      }
    }, []);

    return (
        <div className="block standard green" ref={pagRef}>
          <div className="container-fluid">
              <div className="row">
                <div className="col-xl-9">
                  <h2>
                    {intl.formatMessage({
                      id: `${country}_growth_heading`,
                    })}
                  </h2>
                </div>
              </div>
        <div className="row">
            <div className="col-xl-10 order-xl-last">
                <div className="line-chart" ref={ref}>
                    {animationRan ? <Line data={data} options={lineOptions(country)} /> : null}
                </div>
            </div>
            <div className="col-xl-2 chart-key order-xl-first">
              <ul className="line-chart-key">
                <li className="line-chart-key-pos">{translate(`${country}_pos_heading_line`)}</li>
                <li className="line-chart-key-ecom">
                    {translate(`${country}_growth_subheading`)}
                <ul>
                  <li className="line-chart-key-mobile">{translate(`${country}_ecom_data_2020E_mobile`)}</li>
                  <li className="line-chart-key-desktop">{translate(`${country}_ecom_data_2020E_desktop`)}</li>
                </ul>
                </li>
              </ul>
            </div>
        </div>
        </div>
      </div>
    );
}

export default injectIntl(LineChart);
