import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import Counter from "./counter"

const FastStats = ({ intl, getPaginationData, trackingSection, country }) => {
  const pagRef = useRef(null);
  useEffect(() => {
    if (pagRef.current) getPaginationData('Fast Stats', pagRef);
  }, [pagRef]);

  return (
    <div className="block standard purple fast-stats" ref={pagRef} data-section={trackingSection}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9">
            <h2>{intl.formatMessage({ id: `${country}_fast_heading` })}</h2>
          </div>
        </div>
        <div className="stat-row row">
          <div className="col-md-6">
            <div className="stat-grid large purple">
              <ul>
                <li className="icon-mobile-purple">
                  <Counter id={`${country}_fast_stat_1`} />
                  <span>
                    {intl.formatMessage({
                      id: `${country}_fast_stat_suffix_1`,
                    })}{" "}
                    <span>
                      {intl.formatMessage({
                        id: `${country}_fast_stat_estimated`,
                      })}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="stat-grid green end">
              <h6>
                {intl.formatMessage({
                  id: `${country}_fast_stat_title_4`,
                })}
              </h6>
              <ul>
                <li className="icon-laptop-green">
                  <Counter id={`${country}_fast_stat_6`} />
                  {intl.formatMessage({
                    id: `${country}_fast_stat_ecom`,
                  })}
                </li>
                <li className="icon-pos-green">
                  <Counter id={`${country}_fast_stat_7`} />
                  {intl.formatMessage({
                    id: `${country}_fast_stat_pos`,
                  })}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="stat-row row">
          <div className="col-md-6">
            <div className="stat-grid green">
              <h6>
                {intl.formatMessage({
                  id: `${country}_fast_stat_title_3`,
                })}
              </h6>
              <ul>
                <li className="icon-laptop-dollar-green">
                  <Counter id={`${country}_fast_stat_4`} />
                  {intl.formatMessage({
                    id: `${country}_fast_stat_ecom`,
                  })}
                </li>
                <li className="icon-mobile-dollar-green">
                  <Counter id={`${country}_fast_stat_5`} />
                  {intl.formatMessage({
                    id: `${country}_fast_stat_mcom`,
                  })}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="stat-grid purple end">
              <h6>
                {intl.formatMessage({
                  id: `${country}_fast_stat_title_2`,
                })}
              </h6>
              <ul>
                <li className="icon-laptop-purple">
                  <Counter id={`${country}_fast_stat_2`} />
                  {intl.formatMessage({
                    id: `${country}_fast_stat_ecom`,
                  })}
                </li>
                <li className="icon-mobile-purple">
                  <Counter id={`${country}_fast_stat_3`} />
                  {intl.formatMessage({
                    id: `${country}_fast_stat_mcom`,
                  })}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

FastStats.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(FastStats);
