import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { camelCase } from 'change-case';
import { injectIntl } from "gatsby-plugin-intl";
import { useInView } from 'react-hook-inview';
import options from './barChartOptions';
import useWindowSize from '../../util/useWindowSize';

const BarChart = ({ intl, getPaginationData, country }) => {
    const NEW_MARKETS = ['thailand', 'south_korea', 'uae'];
    const translate = (id) => intl.formatMessage({ id });

    const ECOM = translate('uae_ecom_heading');
    const POS = translate('uae_pos_heading_bar');

    const [ref, isVisible] = useInView({
      threshold: 0.7,
    })
    const [animationRan, setAnimationRan] = useState(false);

    const [active, setActive] = useState(ECOM);

    const ECOM_DATA = {
        argentina: {
            labels: [
                translate('argentina_ecom_data_credit_card'),
                translate('argentina_ecom_data_digital_mobile_wallet'),
                translate('argentina_ecom_data_debit_card'),
                translate('argentina_ecom_data_bank_transfer'),
                translate('argentina_ecom_data_cash_on_delivery'),
                translate('argentina_ecom_data_postpay'),
                translate('argentina_ecom_data_pre_paid_card'),
                translate('argentina_ecom_data_direct_debit'),
                translate('argentina_ecom_data_prepay'),
                translate('argentina_ecom_data_other'),
            ],
            data: [39, 25, 17, 6, 5, 3, 2, 1, 1, 1]
        },
        southAfrica: {
            labels: [
                translate('south_africa_ecom_data_debit_card'),
                translate('south_africa_ecom_data_digital_mobile_wallet'),
                translate('south_africa_ecom_data_credit_card'),
                translate('south_africa_ecom_data_bank_transfer'),
                translate('south_africa_ecom_data_cash_on_delivery'),
                translate('south_africa_ecom_data_pre_paid_card'),
                translate('south_africa_ecom_data_direct_debit'),
                translate('argentina_ecom_data_prepay'),
                translate('south_africa_ecom_data_deferred'),
                translate('south_africa_ecom_data_other'),
            ],
            data: [24, 20, 19, 18, 9, 3, 2, 2, 1, 1],
        },
        malaysia: {
            labels: [
                translate('malaysia_ecom_data_bank_transfer'),
                translate('malaysia_ecom_data_credit_card'),
                translate('malaysia_ecom_data_digital_mobile_wallet'),
                translate('malaysia_ecom_data_cash_on_delivery'),
                translate('malaysia_ecom_data_direct_debit'),
                translate('malaysia_ecom_data_debit_card'),
                translate('malaysia_ecom_data_other'),
                translate('malaysia_ecom_data_deferred'),
                translate('malaysia_ecom_data_pre_paid_card'),
            ],
            data: [28, 17, 14, 12, 12, 12, 2, 2, 1],
        },
        nigeria: {
            labels: [
                translate('nigeria_ecom_data_cash_on_delivery'),
                translate('nigeria_ecom_data_bank_transfer'),
                translate('nigeria_ecom_data_debit_card'),
                translate('nigeria_ecom_data_credit_card'),
                translate('nigeria_ecom_data_digital_mobile_wallet'),
                translate('nigeria_ecom_data_pre_paid_card'),
                translate('nigeria_ecom_data_deferred'),
                translate('nigeria_ecom_data_direct_debit'),
                translate('argentina_ecom_data_prepay'),
                translate('nigeria_ecom_data_other'),
            ],
            data: [23, 21, 18, 17, 7, 4, 3, 3, 2, 2],
        },
        thailand: {
            labels: [
                translate('thailand_ecom_data_bank_transfer'),
                translate('thailand_ecom_data_cash_on_delivery'),
                translate('thailand_ecom_data_digital_mobile_wallet'),
                translate('thailand_ecom_data_credit_card'),
                
                translate('thailand_ecom_data_direct_debit'),
                translate('thailand_ecom_data_debit_card'),
                translate('thailand_ecom_data_deferred'),
                translate('thailand_ecom_data_prepay'),
                translate('thailand_ecom_data_pre_paid_card'),
                translate('thailand_ecom_data_other'),
            ],
            data: [31, 22, 19, 13, 8, 4, 1, 1, 1, 1],
        },
        southKorea: {
            labels: [
                translate('south_korea_ecom_data_credit_card'),
                translate('south_korea_ecom_data_digital_mobile_wallet'),
                translate('south_korea_ecom_data_debit_card'),
                translate('south_korea_ecom_data_bank_transfer'),
                translate('south_korea_ecom_data_deferred'),
                translate('south_korea_ecom_data_pre_paid_card'),
                translate('south_korea_ecom_data_cash_on_delivery'),
                translate('south_korea_ecom_data_direct_debit'),
                translate('south_korea_ecom_data_prepay'),
                translate('south_korea_ecom_data_other'),

            ],
            data: [57, 17, 13, 5, 2, 2, 1, 1, 1, 1],
        },
        uae: {
            labels: [
                translate('uae_ecom_data_credit_card'),
                translate('uae_ecom_data_digital_mobile_wallet'),
                translate('uae_ecom_data_cash_on_delivery'),
                translate('uae_ecom_data_debit_card'),
                translate('uae_ecom_data_bank_transfer'),
                translate('uae_ecom_data_deferred'),
                translate('uae_ecom_data_pre_paid_card'),
                translate('uae_ecom_data_prepay'),
                translate('uae_ecom_data_other'),
            ],
            data: [38, 18, 12, 11, 11, 5, 2, 2, 1],
        },
    };

    const ecomData = (canvas) => {
        const ctx = canvas.getContext('2d');
        const grad = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
        grad.addColorStop(0, '#285BC5');
        grad.addColorStop(1, '#4C12A1');

        return {
            labels: ECOM_DATA[camelCase(country)].labels,
            datasets: [{
                label: '',
                data: ECOM_DATA[camelCase(country)].data,
                backgroundColor: grad,
                barThickness: 21,
            }]
        }
    };

    const POS_DATA = {
        argentina: {
            labels: [
                translate('argentina_pos_data_cash'),
                translate('argentina_pos_data_debit'),
                translate('argentina_pos_data_credit'),
                translate('argentina_pos_data_digital_mobile_wallet'),
                translate('argentina_pos_data_pos'),
                translate('argentina_pos_data_card'),
                translate('argentina_pos_data_prepaid_card'),
            ],
            data: [37, 23, 21, 11, 2, 2, 2],
        },
        southAfrica: {
            labels: [
                translate('south_africa_pos_data_cash'),
                translate('south_africa_pos_data_debit'),
                translate('south_africa_pos_data_credit'),
                translate('south_africa_pos_data_pos'),
                translate('south_africa_pos_data_digital_mobile_wallet'),
                translate('south_africa_pos_data_card'),
            ],
            data: [44, 31, 14, 8, 3, 1],
        },
        malaysia: {
            labels: [
                translate('malaysia_pos_data_cash'),
                translate('malaysia_pos_data_credit'),
                translate('malaysia_pos_data_digital_mobile_wallet'),
                translate('malaysia_pos_data_debit'),
                translate('malaysia_pos_data_pos'),
                translate('malaysia_pos_data_card'),
                translate('malaysia_pos_data_prepaid_card'),
            ],
            data: [43, 24, 13, 12, 4, 3, 2],
        },
        nigeria: {
            labels: [
                translate('nigeria_pos_data_cash'),
                translate('nigeria_pos_data_digital_mobile_wallet'),
                translate('nigeria_pos_data_debit'),
                translate('nigeria_pos_data_card'),
                translate('nigeria_pos_data_pos'),
                translate('nigeria_pos_data_credit'),
            ],
            data: [69, 10, 7, 6, 6, 3],
        },
        thailand: {
            labels: [
                translate('thailand_pos_data_cash'),
                translate('thailand_pos_data_digital_mobile_wallet'),
                translate('thailand_pos_data_credit'),
                translate('thailand_pos_data_debit'),
                translate('thailand_pos_data_pos'),
                translate('thailand_pos_data_card'),
            ],
            data: [62, 20, 9, 5, 3, 2],
        },
        southKorea: {
            labels: [
                translate('south_korea_pos_data_credit'),
                translate('south_korea_pos_data_debit'),
                translate('south_korea_pos_data_cash'),
                translate('south_korea_pos_data_digital_mobile_wallet'),
                translate('south_korea_pos_data_pos'),
                translate('south_korea_pos_data_card'),

            ],
            data: [59, 14, 12, 8, 4, 2],
        },
        uae: {
            labels: [
                translate('uae_pos_data_credit'),
                translate('uae_pos_data_cash'),
                translate('uae_pos_data_debit'),
                translate('uae_pos_data_card'),
                translate('uae_pos_data_digital_mobile_wallet'),
                translate('uae_pos_data_pos'),
                translate('uae_pos_data_charge'),
            ],
            data: [33, 27, 15, 8, 7, 7, 4, 1], 
            //adding this extra value (1) stops the bottom of the chart getting cut off but doesnt appear in the graph
        }
    };

    const posData = (canvas) => {
        const ctx = canvas.getContext('2d');
        const grad = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
        grad.addColorStop(0, '#009775');
        grad.addColorStop(1, '#015B7E');

        return {
            labels: POS_DATA[camelCase(country)].labels,
            datasets: [{
                label: '',
                data: POS_DATA[camelCase(country)].data,
                backgroundColor: grad,
                barThickness: 20,
            }]
        }
    }

    const { width } = useWindowSize();

    let BarChart;
    let myOptions;
    if(width < 768) {
        BarChart = HorizontalBar;
        myOptions = Object.assign({}, options, {
            title: {
                display: false,
                text: 'horizontal',
            },
            layout: {
                padding: {
                    top: 20,
                    right: 40
                }
            },
            scales: {
                yAxes: [{
                    //barThickness: 5,
                    gridLines: {
                        drawBorder: false,
                        display: false,
                    },
                    ticks: {
                        display: true,
                        mirror: true,
                        //fontSize: 20,
                        padding: 0,
                        labelOffset: -20,
                    }
                }],
                xAxes: [{
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        autoSkip: false,
                        display: false,
                        rotation: 90,
                        maxRotation: 90,
                        minRotation: 90,
                        labelOffset: -20,
                        padding: -100,
                    }
                }]
            },
            //animation: {}
        });

        //myOptions.scales.yAxes[0].ticks.display = true;
    } else {
        BarChart = Bar;
        myOptions = Object.assign({}, options, {
            title: {
                display: false,
                text: 'vertical',
            },
            scales: {
                yAxes: [{
                    gridLines: {
                        drawBorder: false,
                        display: false,
                    },
                    ticks: {
                        display: false,
                    }
                }],
                xAxes: [{
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        autoSkip: false,
                        display: false,
                        rotation: 90,
                        maxRotation: 90,
                        minRotation: 90,
                        labelOffset: -20,
                        padding: -100,
                    }
                }]
            }
        });
    }

    const pagRef = useRef(null);

    useEffect(() => {
      if (pagRef.current) getPaginationData('Payment Methods', pagRef);
    }, [pagRef]);

    useEffect(() => {
      if (isVisible && !animationRan) {
          setAnimationRan(true);
          setTimeout(() => options.animation.duration = 0, 0);
      }
    }, [isVisible]);
    
    const backgroundImage = () => {
        if (NEW_MARKETS.includes(country) && active === POS) {
            return `url(${require(`../../images/market-guides/${country}/mg-p2-pos.jpg`)})`;
        }
        return `url(${require(`../../images/market-guides/${country}/mg-p2.jpg`)})`;
    }
    return (
        <div
            className="block standard market-share"
            ref={ref}
            style={{
                minHeight: '850px',
                backgroundImage: backgroundImage(),
            }}
        >
            <div className="container-fluid" ref={pagRef}>
                <div className="row">
                    <div className="col">
                        <h2>{intl.formatMessage({ id: `${country}_comp_heading2` })}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3">
                        <div
                            className={classNames('button chart-buttons grad-purple', { active: active === ECOM })}
                            onClick={() => setActive(ECOM)}
                        >
                            {ECOM}
                        </div>
                        <div
                            className={classNames('button chart-buttons grad-green', { active: active === POS })}
                            onClick={() => setActive(POS)}
                        >
                            {POS}
                        </div>
                    </div>

                    <div className="col-xl-8 offset-xl-1">
                        <div className={`bar-chart ${active === ECOM ? 'ecom' : 'pos'}`}>
                            {animationRan ? <BarChart data={active === ECOM ? ecomData : posData} options={myOptions} /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(BarChart);
