import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

const Intro = ({ intl, trackingSection, country }) => (
  <div className="block standard green market-intro" data-section={trackingSection}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-7">
          <p className="feature-text">
            {intl.formatMessage({ id: `${country}_copy_1` })}
          </p>
        </div>
      </div>
      <div className="row">
        <div
            className="offset-xl-4 col-xl-6"
            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `${country}_copy_2` }) }}
        />
      </div>
    </div>
  </div>
)

export default injectIntl(Intro)
