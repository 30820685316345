import React, { useState } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { sentenceCase, capitalCase } from "change-case";
import useWindowSize from "../util/useWindowSize"
import Layout from "./layout"
import SEO from "./seo"
import HeroGuide from "./hero-guide"
import Intro from "./intro"
import FastStats from "./fast-stats"
import Trends from "./trends"
import SlidingStat from "./sliding-stat"
import Cta from "./cta"
import Summary from "./summary"
import Boilerplate from "./boilerplate"
import Footer from "./footer"
import LineChart from "./charts/lineChart"
import BarChart from "./charts/barChart"
import CardSlider from "./card-slider"
import CardSliderMobile from "./card-slider-mobile"
import Pagination from './pagination'
import MarketGuideBlock from './marketGuideBlock'
import MarketGuideBlockMobile from './marketGuideBlockMobile'
import AltPaymentMethods from './altPaymentMethods'

const MarketGuideTemplate = (props) => {
  const { intl, pageContext } = props;
  const { country } = pageContext;

  const { width } = useWindowSize();

  const [paginationItems, setPaginationItems] = useState([
      {
          id: `${country}_nav_1`,
          text: 'Argentina',
          scrollTo: null,
      },
      {
          id: 'argentina_nav_2',
          text: 'Fast stats',
          scrollTo: null,
      },
      {
          id: 'argentina_nav_3',
          text: 'Market tip',
          scrollTo: null,
      },
      {
          id: 'argentina_nav_4',
          text: 'Payment methods',
          scrollTo: null,
      },
      {
          id: 'argentina_nav_5',
          text: 'Payments forecast',
          scrollTo: null,
      },
      {
          id: 'argentina_nav_6',
          text: 'Projected growth',
          scrollTo: null,
      },
      {
          id: `${country}_nav_7`,
          text: 'The Latin American market',
          scrollTo: null,
      },
  ]);

  const getPaginationData = (text, ref) => {
      const sentenceCased = sentenceCase(text);
      const updated = [...paginationItems];

      for (const i in updated) {
          if (sentenceCase(updated[i].text) == sentenceCased) {
             updated[i].scrollTo = ref.current;
             break;
          }
      }

      setPaginationItems(paginationItems => [...updated]);
  }

  return (
    <Layout>
      <SEO title={country.length > 3 ? capitalCase(country) : country.toUpperCase()} />
      <div className="main">
        {/* MAIN NAVIGATION - OPEN Notes
        - Appears on every page.
        - Clicking .menu in .masthead (Only visible on mobile) adds class .active to .overlay
        - Clicking .close within .overlay will remove the .active class from .overlay
        - .language-select-list can be reusable as it is the same as the language select on desktop
        - .clicking a language li will change the language and add a class of .active to the li, this will be removed when selecting a different language
        */}
        {width >= 1200 ? <Pagination items={paginationItems} /> : null}
        {/* HERO */}

          <HeroGuide
            getPaginationData={getPaginationData}
            trackingSection="hero"
            country={country}
          />

          {/* INTRO TEXT */}
          <Intro trackingSection="intro" country={country} />
          {/* FAST STATS */}
          <FastStats getPaginationData={getPaginationData} trackingSection="fast-stats" country={country} />

          {/* TRENDS */}
          <Trends
            cssClass={["reverse", "top-tip"]}
            heading={intl.formatMessage({
              id: `${country}_comp_heading`,
            })}
            paras={intl.formatMessage({
              id: `${country}_comp_copy`,
            })}
            image={require(`../images/market-guides/${country}/mg-p1.jpg`)}
            getPaginationData={getPaginationData}
            trackingSection="content"
          />

          {/* CTA */}
          <Cta
            header={intl.formatMessage({ id: `${country}_cta_header_1` })}
            content={intl.formatMessage({
              id: `${country}_cta_body_1`,
            })}
            button={intl.formatMessage({ id: "cta_download" })}
            trackingSection="cta-1"
          />
          {/* MARKET SHARE */}
          <BarChart getPaginationData={getPaginationData} country={country} />
          {/* SLIDING STATS */}
          <SlidingStat
            title={intl.formatMessage({ id: `${country}_forecast_heading` })}
            stat1Title={intl.formatMessage({
              id: `${country}_forecast_data_sales_ecom`,
            })}
            stat1Value={intl.formatMessage({
              id: `${country}_forecast_sales_ecom_percent`,
            })}
            stat1Description={intl.formatMessage({
              id: `${country}_forecast_data_sales_ecom_copy`,
            })}
            stat1Image={require(`../images/market-guides/${country}/mg-p3.jpg`)}
            stat2Title={intl.formatMessage({
              id: `${country}_forecast_data_sales_mcom`,
            })}
            stat2Value={intl.formatMessage({
              id: `${country}_forecast_sales_mcom_percent`,
            })}
            stat2Description={intl.formatMessage({
              id: `${country}_forecast_data_sales_copy`,
            })}
            stat2Image={require(`../images/market-guides/${country}/mg-p4.jpg`)}
            getPaginationData={getPaginationData}
            trackingSection="stats"
          />
          {/* CARD SLIDER */}
          {width < 1200 ?
            <CardSliderMobile trackingSection="card-slide" country={country} /> :
            <CardSlider trackingSection="card-slide" country={country} />
          }

          {/* CTA */}
          <Cta
            header={intl.formatMessage({ id: "cta_explore" })}
            content={intl.formatMessage({
              id: "explore_copy"
            })}
            button={intl.formatMessage({ id: "cta_download" })}
            trackingSection="cta-2"
          />

          {/* PROJECTED GROWTH */}
            <LineChart
                getPaginationData={getPaginationData}
                trackingSection="line-chart"
                country={country}
            />

          {/* ALTERNATIVE PAYMENT METHODS */}
          <AltPaymentMethods country={country} />

          {/* SUMMARY BLOCK */}
          <Summary
            background={require(`../images/market-guides/${country}/mg-p5.jpg`)}
            heading={intl.formatMessage({
              id: `${country}_la_opportunity_heading`,
            })}
            heading2={intl.formatMessage({
                id: `${country}_la_opportunity_heading_1`,
                defaultMessage: "null",
            })}
            para1={intl.formatMessage({
              id: `${country}_la_opportunity_copy`,
            })}
            getPaginationData={getPaginationData}
            trackingSection="summary"
          />
          {width < 1200 ?
              <MarketGuideBlockMobile country={country} trackingSection="guide-block" />
              :
              <MarketGuideBlock country={country} trackingSection="guide-block" />
          }

          {/* FOOTER CTA */}
          <Boilerplate trackingSection="boilerplate" />
          {/* FOOTER */}
          <Footer trackingSection="footer" />
      </div>
    </Layout>
  )
}

export default injectIntl(MarketGuideTemplate);
