import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { camelCase } from 'change-case';
import { paymentMethods } from './card-slider';

const CardSliderMobile = ({ intl, trackingSection, country }) => (
    <>
        <div className="block standard title purple">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9">
                        <h2>
                            {" "}
                            {intl.formatMessage({
                                id: `${country}_card_heading`,
                            })}
                        </h2>
                        <h5 className="scroll-cards-label">
                            {intl.formatMessage({
                                id: `${country}_card_subheading`,
                            })}
                        </h5>
                        <p className="small adjustment-text">{intl.formatMessage({ id: 'adjusted_copy' })}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="scroll-cards-scroll" data-section={trackingSection}>
            <div className="block standard scroll-cards">
                {paymentMethods[camelCase(country)].map(method => (
                    method.className === 'logo-other'
                        ? (
                            <div className="item" key={method.id}>
                                <div className="payment-card logo-other">
                                    <data>
                                        {intl.formatMessage({
                                            id: `${country}_card_others_percent`,
                                        })}
                                    </data>
                                    <span>
                                        {intl.formatMessage({
                                            id: `${country}_card_data_others`,
                                        })}
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="item" key={method.id}>
                                <div className={`payment-card ${method.className}`}>
                                    <data>
                                        {intl.formatMessage({
                                            id: method.id,
                                        })}
                                    </data>
                                </div>
                            </div>
                        )
                ))}
            </div>
        </div>

    </>
);

export default injectIntl(CardSliderMobile);
