import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useCountUp } from "react-countup"
import { injectIntl } from "gatsby-plugin-intl"
import { useInView } from "react-hook-inview"

const Counter = ({ id, intl }) => {
  const { countUp, start } = useCountUp({
    start: 0,
    end: intl.formatMessage({ id }),
    duration: 3,
    suffix: intl.formatMessage({ id: 'argentina_fast_stat_percent' }),
  })

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  const [animationRan, setAnimationRan] = useState(false)

  useEffect(() => {
    if (isVisible && !animationRan) {
      start()
      setAnimationRan(true)
    }
  })

  return (
    <>
      <data ref={ref}>{animationRan ? countUp : "0%"}</data>{" "}
    </>
  )
}

Counter.propTypes = {
  id: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
}

export default injectIntl(Counter)
