import React, { useRef, useState, useEffect } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { injectIntl } from 'gatsby-plugin-intl';
import useWindowSize from '../util/useWindowSize';
import { getWindow } from 'ssr-window';
import { camelCase } from 'change-case';

const window = getWindow();

export const paymentMethods = {
    argentina: [
        {
            className: 'logo-visa',
            id: 'argentina_card_visa_percent',
        },
        {
            className: 'logo-mastercard',
            id: 'argentina_card_mastercard_percent',
        },
        {
            className: 'logo-amex',
            id: 'argentina_card_american_express_percent',
        },
        {
            className: 'logo-cabal',
            id: 'argentina_card_cabal_percent',
        },
        {
            className: 'logo-naranja',
            id: 'argentina_card_tarjeta_naranja_percent',
        },
        {
            className: 'logo-other',
            id: 'argentina_card_other_percent',
        },
    ],
    southAfrica: [
        {
            className: 'logo-visa',
            id: 'south_africa_card_visa_percent',
        },
        {
            className: 'logo-mastercard',
            id: 'south_africa_card_mastercard_percent',
        },
        {
            className: 'logo-amex',
            id: 'south_africa_card_american_express_percent',
        },
    ],
    malaysia: [
        {
            className: 'logo-visa',
            id: 'malaysia_card_visa_percent',
        },
        {
            className: 'logo-mastercard',
            id: 'malaysia_card_mastercard_percent',
        },
        {
            className: 'logo-mydebit',
            id: 'malaysia_card_mydebit_percent',
        },
        {
            className: 'logo-amex',
            id: 'malaysia_card_american_express_percent',
        },
    ],
    nigeria: [
        {
            className: 'logo-verve',
            id: 'nigeria_card_verve_percent',
        },
        {
            className: 'logo-visa',
            id: 'nigeria_card_visa_percent',
        },
        {
            className: 'logo-mastercard',
            id: 'nigeria_card_mastercard_percent',
        },
    ],
    southKorea: [
        {
            className: 'logo-visa',
            id: 'south_korea_card_visa_percent',
        },
        {
            className: 'logo-mastercard',
            id: 'south_korea_card_mastercard_percent',
        },
        {
            className: 'logo-union-pay', // this should be logo-china-unionpay
            id: 'south_korea_card_china_unionpay_percent',
        },
        {
            className: 'logo-other',
            id: 'south_korea_card_other_percent',
        },
        {
            className: 'logo-jcb', // this should be logo-jcb
            id: 'south_korea_card_jcb_percent',
        },
        {
            className: 'logo-amex',
            id: 'south_korea_card_american_express_percent',
        },
    ],
    thailand: [
        {
            className: 'logo-visa',
            id: 'thailand_card_visa_percent',
        },
        {
            className: 'logo-mastercard',
            id: 'thailand_card_mastercard_percent',
        },
        {
            className: 'logo-thai-payment-network', 
            id: 'thailand_card_thai_payment_network_percent',
        },
        {
            className: 'logo-amex',
            id: 'thailand_card_american_express_percent',
        },
        {
            className: 'logo-jcb',
            id: 'thailand_card_jcb_percent',
        },
    ],
    uae: [
        {
            className: 'logo-visa',
            id: 'uae_card_visa_percent',
        },
        {
            className: 'logo-mastercard',
            id: 'uae_card_mastercard_percent',
        },
        {
            className: 'logo-amex',
            id: 'uae_card_american_express_percent',
        },
        {
            className: 'logo-diners-club', // this should be logo-diners-club
            id: 'uae_card_diners_club_percent',
        },
    ],
}

const CardSlider = ({ intl, trackingSection, country }) => {
    const { width } =  useWindowSize();
    const slides = useRef(null);

    const [slideWidth, setSlideWidth] = useState(0);

    useEffect(() => {

        const { current } = slides;
        let slidesWidth = 0;
        const blockStyle = window.getComputedStyle ? getComputedStyle(current, null) : current.currentStyle;

        const blockPaddingLeft = parseInt(blockStyle.paddingLeft) || 0;
        const blockPaddingRight = parseInt(blockStyle.paddingRight) || 0;

        Array.from(current.querySelectorAll('.item')).forEach(item => {
            const style = window.getComputedStyle ? getComputedStyle(item, null) : item.currentStyle;
            const marginLeft = parseInt(style.marginLeft) || 0;
            const marginRight = parseInt(style.marginRight) || 0;
            const itemWidth = marginLeft + marginRight + item.clientWidth;

            slidesWidth = slidesWidth + itemWidth;
        });
        setSlideWidth(`-${slidesWidth - width + blockPaddingRight + blockPaddingLeft}px`);

        return () => {

        }
    }, [width])

    return (
        <div>
            <Controller>
                <Scene
                    duration="200%"
                    pin
                    triggerHook=".1"
                    pinSettings={{
                        pushFollowers: true
                    }}

                >
                    <Timeline>
                    <div className="block standard title purple" data-section={trackingSection}>
                      <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <h2>
                                    {" "}
                                    {intl.formatMessage({
                                        id: `${country}_card_heading`,
                                    })}
                                </h2>
                                <h5 className="scroll-cards-label">
                                    {intl.formatMessage({
                                        id: `${country}_card_subheading`,
                                    })}
                                </h5>
                                <p className="small adjustment-text">{intl.formatMessage({ id: 'adjusted_copy' })}</p>
                            </div>
                        </div>
                      </div>
                    </div>
                    <Tween
                        to={{
                            translateX: slideWidth
                        }}
                    >
                        <div>
                        <div className="block standard scroll-cards" ref={slides}>
                            {paymentMethods[camelCase(country)].map(method => (
                                method.className === 'logo-other'
                                    ? (
                                        <div className="item" key={method.id}>
                                            <div className="payment-card logo-other">
                                                <data>
                                                    {intl.formatMessage({
                                                        id: `${country}_card_others_percent`,
                                                    })}
                                                </data>
                                                <span>
                                                    {intl.formatMessage({
                                                        id: `${country}_card_data_others`,
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="item" key={method.id}>
                                            <div className={`payment-card ${method.className}`}>
                                                <data>
                                                    {intl.formatMessage({
                                                        id: method.id,
                                                    })}
                                                </data>
                                            </div>
                                        </div>
                                    )
                            ))}
                        </div>
                      </div>
                    </Tween>
                    </Timeline>
                </Scene>
            </Controller>
        </div>
    )
}

export default injectIntl(CardSlider);
