import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { camelCase } from 'change-case';
import classNames from 'classnames';

const AltPaymentMethods = ({ country, intl }) => {
    const logoData = {
        argentina: [
            {
                logo: '../images/logos/alt-payment-methods/argentina/logo_1.svg',
                width: '205',
                height: '59',
            },
            {
                logo: '../images/logos/alt-payment-methods/argentina/logo_2.svg',
                width: '153',
                height: '56',
            },
            {
                logo: '../images/logos/alt-payment-methods/argentina/logo_3.svg',
                width: '160',
                height: '50',
            },
        ],
        malaysia: [
            {
                logo: '../images/logos/alt-payment-methods/malaysia/logo_1.svg',
                width: '160',
                height: '50',
            },
            {
                logo: '../images/logos/alt-payment-methods/malaysia/logo_2.svg',
                width: '154',
                height: '66',
            },
            {
                logo: '../images/logos/alt-payment-methods/malaysia/logo_3.svg',
                width: '190',
                height: '74',
            },
        ],
        southAfrica: [
            {
                logo: '../images/logos/alt-payment-methods/south_africa/logo_1.svg',
                width: '160',
                height: '50',
            },
            {
                logo: '../images/logos/alt-payment-methods/south_africa/logo_2.svg',
                width: '170',
                height: '66',
            },
            {
                logo: '../images/logos/alt-payment-methods/south_africa/logo_3.svg',
                width: '118',
                height: '61',
            },
        ],
        nigeria: [
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_1.svg',
                width: '160',
                height: '50',
            },
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_2.svg',
                width: '170',
                height: '66',
            },
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_3.svg',
                width: '118',
                height: '61',
            },
        ],
        southKorea: [
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_1.svg',
                width: '160',
                height: '50',
            },
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_2.svg',
                width: '170',
                height: '66',
            },
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_3.svg',
                width: '118',
                height: '61',
            },
        ],
        thailand: [
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_1.svg',
                width: '160',
                height: '50',
            },
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_2.svg',
                width: '170',
                height: '66',
            },
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_3.svg',
                width: '154',
                height: '66',
            },
        ],
        uae: [
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_1.svg',
                width: '160',
                height: '50',
            },
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_2.svg',
                width: '170',
                height: '66',
            },
            {
                logo: '../images/logos/alt-payment-methods/nigeria/logo_3.svg',
                width: '118',
                height: '61',
            },
        ],
    }

    return (
        <div className="block standard alt-payment-methods" data-section="alternative-payments">
          <div className="container-fluid">
            <div className="row align-items-end">
              <div className="col-xl-4 ">
                <h3>
                  {intl.formatMessage({
                    id: `${country}_alternative_methods_heading`,
                  })}
                </h3>
              </div>
              {logoData[camelCase(country)].map((data, i)=> {
                  const { width, height } = data;

                  return (
                      <div className={classNames('col', { 'offset-xl-1': i === 0 })} key={`${country}-${i+1}`}>
                        <img
                            src={require(`../images/logos/alt-payment-methods/${country}/logo_${i+1}.svg`)}
                            alt=""
                            width={`${width}px`}
                            height={`${height}px`}
                        />
                      </div>
                  )
              })}
            </div>
          </div>
        </div>
    );
}

export default injectIntl(AltPaymentMethods);
