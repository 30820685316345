import { Chart } from 'chart.js';

export const customAxisLines = (country) => {

    return {
        id: 'custom-axis-lines',
        afterDatasetsDraw: function (chart, easing) {
            this.color = '#cccccc';
    
            this.drawXAxis(chart);
            this.drawYAxis(chart);
        },
        drawXAxis: function(chartInstance) {
            const {ctx} = chartInstance.chart;
    
            const {
                left,
                right,
                top,
            } = chartInstance.scales['x-axis-0'];
    
            ctx.beginPath();
            ctx.strokeStyle = this.color;
            ctx.moveTo(left, top + 25);
            ctx.lineTo(right, top + 25);
            ctx.stroke();
        },
        drawYAxis: function(chartInstance) {
            const {ctx} = chartInstance.chart;
    
            const {
                left,
                top,
                bottom,
            } = chartInstance.scales['y-axis-0'];
    
            const offsetLeft = left - 25;
            const offsetTop = top + 20;
            const offsetBottom = bottom;
    
            const middle = ((bottom - top) / 2) + 20; // dont know why I have to add this random 20 here
            const zigZagHeight = 15;
            const zigZagWidth = 15;
    
            // top half of line
            ctx.beginPath();
            ctx.strokeStyle = this.color;
            ctx.moveTo(offsetLeft, offsetTop);
            ctx.lineTo(offsetLeft, middle - (zigZagHeight / 2));
            ctx.stroke();
    
            // bottom half of line
            ctx.beginPath();
            ctx.strokeStyle = this.color;
            ctx.moveTo(offsetLeft, middle + (zigZagHeight / 2));
            ctx.lineTo(offsetLeft, offsetBottom);
            ctx.stroke();
    
            // zigzag - top right
            ctx.beginPath();
            ctx.strokeStyle = this.color;
            ctx.moveTo(offsetLeft, middle - (zigZagHeight / 2));
            ctx.lineTo(offsetLeft + (zigZagWidth / 2), middle - (zigZagHeight / 4));
            ctx.stroke();
    
            // zigzag - bottom left
            ctx.beginPath();
            ctx.strokeStyle = this.color;
            ctx.moveTo(offsetLeft, middle + (zigZagHeight / 2));
            ctx.lineTo(offsetLeft - (zigZagWidth / 2), middle + (zigZagHeight / 4));
            ctx.stroke();
    
            // zigzag - middle join
            ctx.beginPath();
            ctx.strokeStyle = this.color;
            ctx.moveTo(offsetLeft + (zigZagWidth / 2), middle - (zigZagHeight / 4));
            ctx.lineTo(offsetLeft - (zigZagWidth / 2), middle + (zigZagHeight / 4));
            ctx.stroke();
    
            // labels
            ctx.font = Chart.helpers.fontString(
                Chart.defaults.global.defaultFontSize,
                Chart.defaults.global.defaultFontStyle,
                Chart.defaults.global.defaultFontFamily,
            );
            ctx.fillStyle = '#012834';
            ctx.textBaseLine = 'bottom';
            ctx.textAlign = 'center';
            ctx.font = "16px SourceSansProBold";

            if (country == 'south_korea') {
                ctx.fillText('$1220Bn', offsetLeft, top);
            } else {
                ctx.fillText('$350Bn', offsetLeft, top);
            }
            ctx.fillText('$0Bn', offsetLeft, bottom + 25);
        }
    }
};


export const lineOptions = (country) => {

    return {
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 60,
                right: 0,
                top: 20,
                bottom: 0
            }
        },
        legend: {
            display: false,
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    display: false,
                    drawBorder: false,
                    tickMarkLength: 30,
                    color: 'transparent',
                },
                ticks: {
                    fontSize: 16,
                    fontFamily: 'SourceSansProBold',
                    fontColor: '#012834',
                    padding: 10,
                }
            }],
            yAxes: [
                {
                    display: false,
                    type: 'logarithmic',
                    ticks: {
                        min: 0,
                        max: country === 'south_korea' ? 1400 : 350,
                    }
                },
            ],
        },
        tooltips: {
            enabled: false,
        },
        animation: {
            duration: 1000,
            onComplete: (canvas) => {
                const { chart } = canvas;
                chart.ctx.font = Chart.helpers.fontString(
                    Chart.defaults.global.defaultFontSize,
                    Chart.defaults.global.defaultFontStyle,
                    Chart.defaults.global.defaultFontFamily,
    
                );
                chart.ctx.fillStyle = '#012834';
                chart.ctx.textBaseLine = 'bottom';
                chart.ctx.textAlign = 'center';
    
                chart.config.data.datasets.forEach((dataset, i) => {
                    const meta = chart.controller.getDatasetMeta(i);
                    meta.data.forEach((bar, index) => {
                        const data = dataset.data[index];
                        chart.ctx.font = "16px SourceSansProBold";
                        // if (index === 1 && i === 3 && country === 'thailand') {
                        //     chart.ctx.fillText(data, bar._model.x, bar._model.y + 18);
                        // } else {
                        //     
                        // }
                        chart.ctx.fillText(data, bar._model.x, bar._model.y -5);
                    });
                });
            },
        },
    }
};
