import { Chart } from 'chart.js';
import drawRoundedBars from './drawRoundedBars';

Chart.defaults.global.defaultFontColor = '#fff';
Chart.defaults.global.defaultFontFamily = 'SourceSansProBold';
Chart.elements.Rectangle.prototype.draw = drawRoundedBars;

export default {
    maintainAspectRatio: false,
    cornerRadius: 30,
    stackedRounded: true,
    fullCornerRadius: true,

    scales: {
        yAxes: [{
            gridLines: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                display: false,
            }
        }],
        xAxes: [{
            gridLines: {
                display: false,
            },
            ticks: {
                autoSkip: false,
                display: false,
                rotation: 90,
                maxRotation: 90,
                minRotation: 90,
                labelOffset: -20,
                padding: -100,
            }
        }]
    },
    layout: {
        padding: {
            top: 20,
        }
    },
    legend: {
        display: false,
    },
    tooltips: {
        enabled: false,
    },
    hover: {
        animationDuration: 0,
    },
    animation: {
        duration: 1000,
        // easing: 'easeOutQuart',

        // delay: 1000,

        onComplete: (canvas) => {
            const { chart } = canvas;
            chart.ctx.font = Chart.helpers.fontString(
                Chart.defaults.global.defaultFontSize,
                Chart.defaults.global.defaultFontStyle,
                Chart.defaults.global.defaultFontFamily,
            );
            chart.ctx.fillStyle = '#fff';
            chart.ctx.textBaseLine = 'bottom';
            chart.ctx.textAlign = 'center';
            chart.ctx.font = "18px sourceSansProBold";

            if(chart.options.title.text === 'horizontal') {
                chart.config.data.datasets.forEach((dataset, i) => {
                    const meta = chart.controller.getDatasetMeta(i);
                    meta.data.forEach((bar, index) => {
                        const data = dataset.data[index];
                        chart.ctx.fillText(data + '%', bar._model.x + 20, bar._model.y + 5);
                    });
                });
            }

            if(chart.options.title.text === 'vertical') {
                chart.config.data.datasets.forEach((dataset, i) => {
                    const meta = chart.controller.getDatasetMeta(i);
                    meta.data.forEach((bar, index) => {
                        const data = dataset.data[index];
                        chart.ctx.fillText(data + '%', bar._model.x, bar._model.y - 10);
                    });
                });

                // spin context
                chart.ctx.rotate(-90 * Math.PI / 180);
                // loop through datasets and write in custom labels
                chart.config.data.datasets.forEach(function(dataset, i) {
                    var meta = chart.controller.getDatasetMeta(i);
                    meta.data.forEach(function(bar, index) {

                        chart.ctx.textAlign = "left";

                        var label = bar._model.label;
                        var xOffset = bar._model.x - 30;
                        var yOffset = (chart.scales['x-axis-0'].bottom - 10) * -1;
                        chart.ctx.fillText(label, yOffset, xOffset);
                    });
                });
                // return context to correct orientation
                chart.ctx.rotate(90 * Math.PI / 180);
            }

        },
    }
};
